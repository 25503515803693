<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :indexSearch="indexSearch")
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingSaleCategoriesList || isLoadingSaleCategoriesListArchived || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des catégories de vente...
    sync-tree-grid#overviewtreegrid(ref="overviewtreegrid" v-else :uid="uid" :editSettings='editSettings' :parentIdMapping="parentIdMapping" :idMapping="idMapping" :hasChildMapping="hasChildMapping" :treeColumnIndex="treeColumnIndex" :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @this.$refs.overviewtreegrid="this.$refs.overviewtreegrid" @deleteButtonClicked="deleteButtonClicked" @actionBegin="actionBegin" @archiveButtonClicked="archiveButtonClicked")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncTreeGrid,
    HeaderGrid,
  },
  data() {
    return {
      indexSearch: 0,
      name: "saleCategorie",
      uid: "saleCategories",
      archive: false,
      headerData: [],
      headerLoaded: false,
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      archiveMode: true,
      searchPlaceholder: "Rechercher une catégorie de vente",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouvelle catégorie",
        titleEdit: "Modifier la catégorie",
        titleField: "label",
      },
      allowGrouping: false,
      parentIdMapping: "parentSaleCategoryId",
      idMapping: "id",
      hasChildMapping: "hasChild",
      treeColumnIndex: 1,
    };
  },
  computed: {
    ...mapGetters([
      "saleCategoriesList",
      "saleCategoriesListArchived",
      "isLoadingSaleCategoriesList",
      "isLoadingSaleCategoriesListArchived",
    ]),
    dataFiltered() {
      if (this.archive) {
        return this.$store.getters["saleCategoriesListArchived"];
      } else {
        return this.$store.getters["saleCategoriesList"];
      }
    },
  },
  created() {
    this.getSaleCategories({}).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getSaleCategories",
      "getSaleCategoriesArchived",
      "updateSaleCategory",
      "deleteSaleCategories",
      "createSaleCategory",
      "archiveSaleCategories",
    ]),
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "string",
          field: "color",
          headerText: "Couleur",
          width: 60,
          maxWidth: 60,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          template: "colorTemplate",
          visible: true,
          unavailableExport: true,
          edit: { type: "colorpicker" },
        },
        {
          type: "string",
          field: "label",
          headerText: "Nom",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowEditing: true,
          visible: true,
        },
        {
          type: "number",
          field: "parentSaleCategoryId",
          format: "formatNumber",
          headerText: "Catégorie parent",
          width: 1,
          maxWidth: 1,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.dataFiltered,
            fields: {
              text: "label",
              value: "id",
              hasChildren: "hasChild",
              parentValue: "parentSaleCategoryId",
              expanded: "hasChild",
              dataSource: this.dataFiltered,
            },
            type: "dropdowntree",
          },
        },
      ];
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewtreegrid.onFilterTextBoxChanged(search);
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        await this.createSaleCategory({
          saleCategory: args.data,
        });
      }

      if (args.requestType === "save" && args.action === "edit") {
        await this.updateSaleCategory({
          saleCategory: args.data,
        });
      }
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewtreegrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.getSaleCategoriesArchived({});
        this.archive = true;
      } else if (args.item.type == "current") {
        this.getSaleCategories({});
        this.archive = false;
      } else {
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked() {},
    clickAction() {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.addRecord();
    },
    refreshRow(val) {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.setRowData(
        val.id,
        val
      );
    },
    async deleteButtonClicked(args) {
      await this.deleteSaleCategories({
        saleCategoryIds: [args.rowData.id],
      });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    async archiveButtonClicked(args) {
      await this.archiveSaleCategories({
        saleCategoryIds: [args.rowData.id],
      });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
  },
};
</script>
